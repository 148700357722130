import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import sanitizer from 'utilities/sanitizer'
import getUniqueEpisodes from 'utilities/getUniqueEpisodes'

import SEO from 'SEO'
import IndexPageTemplate from 'IndexPage'

const IndexPage = ({ data }) => {
  const {
    markdownRemark: page,
    episodes,
    shows
  } = data

  const { edges: recentEpisodesEdges } = episodes
  const { edges: allShowsEdges } = shows

  return (
    <Fragment>
      <SEO
        title='Home'
      />
      <IndexPageTemplate
        data={page}
        recentEpisodes={getUniqueEpisodes(sanitizer(recentEpisodesEdges))}
        allShows={sanitizer(allShowsEdges)}
      />
    </Fragment>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPage

export const PageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "pages/index" } }) {
      id
      html
      frontmatter {
        hero {
          image
          mobileImage
          type
          item {
            fields {
              slug
            }
            frontmatter {
              title
              shortDescription
              categories {
                frontmatter {
                  title
                  hex
                }
              }
              show {
                fields {
                  slug
                }
                frontmatter {
                  title
                  hex
                  categories {
                    frontmatter {
                      title
                      hex
                    }
                  }
                  artwork
                }
              }
              audioUrl
            }
          }
        }
      }
    }
    episodes: allMarkdownRemark(
      filter: {
        frontmatter: {
          showInRecentEpisodes: {
            eq: true
          }
          templateKey: {
            eq: "templates/EpisodeTemplate"
          }
        }
      }
      sort: {
        fields: [frontmatter___publishDate]
        order: DESC
      }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            audioUrl
            audioDuration
            publishDate(formatString: "MMMM D, YYYY")
            show {
              fields {
                slug
              }
              frontmatter {
                title
                artwork
              }
            }
          }
        }
      }
    }
    shows: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "templates/ShowTemplate" } }
      }
      sort: {
        fields: [frontmatter___title]
        order: ASC
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            artwork
          }
        }
      }
    }
  }
`
