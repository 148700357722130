/**
 * Ensure an array of episodes has only one
 * episode per show.
 */
const getUniqueEpisodes = (episodes) => {
  // Create an empty array and Map() to hold our
  // sanitised data
  const uniqueEpisodes = []
  const map = new Map()

  // Loop each node (episode)
  for (const episode of episodes) {
    if (episode.show) {
      const show = episode.show.frontmatter.title

      // Check if map already has show title
      if (!map.has(show)) {
        map.set(show, true)

        // Push episode to our array
        uniqueEpisodes.push(episode)
      }
    }
  }

  return uniqueEpisodes
}

export default getUniqueEpisodes
