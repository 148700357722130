import React from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'

import CarouselSlide from './HeroCarouselSlide'

const HeroCarouselView = ({ items, swiperProps }) => (
  <section
    className='c-hero-carousel'
  >
    <Swiper
      {...swiperProps}
    >
      {
        items.map((item, key) => (

          /* Must be wrapped in an HTML element (can't be a React component) */
          <article
            className='c-hero-carousel-slide'
            key={key}
          >
            <CarouselSlide
              index={key}
              {...item}
            />
          </article>
        ))
      }
    </Swiper>
  </section>
)

HeroCarouselView.propTypes = {
  items: PropTypes.array.isRequired,
  swiperProps: PropTypes.object
}

export default HeroCarouselView
