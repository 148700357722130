import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as ArrowRight } from 'assets/img/svg/arrow-right.svg'
import { ReactComponent as ArrowLeft } from 'assets/img/svg/arrow-left.svg'

import View from './HeroCarouselView'

const Hero = ({ items }) => {
  // Set the parameters for the Swiper
  const swiperProps = {
    grabCursor: true,
    slidesPerView: 1,
    parallax: true,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: `.c-hero-carousel__nav.c-hero-carousel__nav--next`,
      prevEl: `.c-hero-carousel__nav.c-hero-carousel__nav--prev`
    },
    renderNextButton: () =>
      <button className='c-hero-carousel__nav c-hero-carousel__nav--next'>
        <ArrowRight />
        <span className='u-hide-visually'>Next</span>
      </button>,
    renderPrevButton: () =>
      <button className='c-hero-carousel__nav c-hero-carousel__nav--prev'>
        <ArrowLeft />
        <span className='u-hide-visually'>Prev</span>
      </button>,
    pagination: {
      el: `.c-slider-pagination`,
      clickable: true,
      renderBullet: (index, className) => `<span class="c-slider-pagination__bullet ${className}"></span>`
    }
  }

  return (
    <View
      items={items}
      swiperProps={swiperProps}
    />
  )
}

Hero.propTypes = {
  items: PropTypes.array.isRequired
}

export default Hero
