import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'Layout'
import View from './IndexPageView'

const IndexPage = ({ data, recentEpisodes, recentArticles, allShows }) => {
  const { frontmatter } = data
  const {
    hero,
    fixedBlock,
    mediaBlocks
  } = frontmatter

  // Formalise the data for the Hero
  const heroItems = hero.map(({ item, image, mobileImage, type, title, slug, subtitle, ctaText, categories }) => {
    if (type === 'image') {
      return {
        title,
        slug,
        image,
        type,
        blurb: subtitle,
        ctaText,
        categories,
        mobileImage
      }
    }

    return {
      title: item.frontmatter.title,
      slug: item.fields.slug,
      image: image,
      mobileImage: mobileImage,
      type: type,
      show: item.frontmatter.show,
      audioUrl: item.frontmatter.audioUrl,
      blurb: item.frontmatter.shortDescription,
      categories: item.frontmatter.categories
    }
  })

  return (
    <Layout>
      <View
        heroItems={heroItems}
        fixedBlock={fixedBlock}
        mediaBlocks={mediaBlocks}
        episodes={recentEpisodes}
        articles={recentArticles}
        shows={allShows}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  episodes: PropTypes.array,
  shows: PropTypes.array,
  articles: PropTypes.array
}

export default IndexPage
