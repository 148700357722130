import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import HeroCarousel from 'HeroCarousel'
import EpisodeRoll from 'EpisodeRoll'
import ShowRoll from 'ShowRoll'

const IndexPageView = ({ heroItems, episodes, shows, articles }) => (
  <Fragment>
    {
      heroItems &&
        <HeroCarousel
          items={heroItems}
        />
    }
    {
      episodes &&
        <EpisodeRoll
          episodes={episodes}
        />
    }
    {
      shows &&
        <ShowRoll
          shows={shows}
          split={4}
        />
    }
  </Fragment>
)

IndexPageView.propTypes = {
  heroItems: PropTypes.array.isRequired,
  episodes: PropTypes.array,
  shows: PropTypes.array,
  articles: PropTypes.array
}

export default IndexPageView
