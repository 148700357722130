import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from 'Image'

import PlayButton from 'AudioPlayer/AudioPlayerUpdateTrackButton'

import Tag from './HeroCarouselSlideTag'

const HeroCarouselSlide = ({ ...props }) => {
  const {
    slug,
    title,
    image,
    mobileImage,
    type,
    audioUrl,
    show,
    blurb,
    index,
    categories
  } = props

  /**
   * Create an object to hold the track data, which
   * will be used if there is an audioUrl value present.
   */
  const track = {
    url: audioUrl,
    name: title,
    slug: slug,
    show: show && Object.assign(show.frontmatter, show.fields)
  }

  return (
    <Fragment>
      <figure
        className='c-hero-carousel-slide__image'
      >
        <Image
          image={image}
          mobileImage={mobileImage}
          width={2000}
          sizes='100vw'
          className='u-fill-parent'
          alt={title}
          lazyload={index !== 0}
        />
      </figure>
      <div
        className='o-container'
      >
        <div
          className='c-hero-carousel-slide__content'
        >
          { show && <Tag text={show.frontmatter.title} colorHex={show.frontmatter.hex} /> }

          {
            categories &&
            categories.map(({ frontmatter }, categoryIndex) => <Tag key={categoryIndex} text={frontmatter.title} colorHex={frontmatter.hex} />)
          }

          <h2
            className='c-hero-carousel-slide__title'
            data-swiper-parallax='-200'
          >
            {title}
          </h2>
          {
            blurb &&
              <p className='c-hero-carousel-slide__blurb'>{blurb}</p>
          }
          {
            audioUrl &&
              <PlayButton
                track={track}
                parentClassName='c-hero-carousel-slide__button'
                iconClassName='u-color-grey'
                data-swiper-parallax='-100'
              />
          }
          { slug &&
            <Link
              to={slug}
              className='c-btn c-btn--invert'
            >
              View { type }
            </Link>
          }
        </div>
      </div>
    </Fragment>
  )
}

HeroCarouselSlide.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  audioUrl: PropTypes.string,
  show: PropTypes.object,
  index: PropTypes.number.isRequired
}

export default HeroCarouselSlide
