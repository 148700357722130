import React from 'react'

const HeroCarouselSlideTag = ({ text, colorHex }) => (
  <span
    className='c-hero-carousel-slide__tag'
    data-swiper-parallax='-300'
    style={{
      backgroundColor: colorHex
    }}
  >
    {text}
  </span>
)

export default HeroCarouselSlideTag
